    // Start of Selection
    import React, { useEffect } from 'react';
    import '../styles/App.css';
    import '../context/ThemeMode.css';

    // Layout components
    import HeaderInfo from './Headerinfo';
    import NavbarBottom from './NavbarBottom';
    import Footer from './Footer';

    // Feature components
    import CallToAction from './CallToAction';
    import Work from './Work';
    import Expertise from './Expertise';
    import Vision from './Vision';

    // UI components
    import ThemeMode from '../context/ThemeMode';
    import HireMeButton from './HireMeButton';

    function App() {
      useEffect(() => {
        const disableContextMenu = (e) => e.preventDefault();
        document.addEventListener('contextmenu', disableContextMenu);

        return () => {
          document.removeEventListener('contextmenu', disableContextMenu);
        };
      }, []);

      return (
        <div className="App">
          <header>
            <HeaderInfo />
            <ThemeMode />
            <NavbarBottom />
          </header>
          <main className="main-content">
            <HireMeButton />
            <CallToAction />
            <Work />
            <Expertise />
            <Vision />
            <Footer />
          </main>
        </div>
      );
    }

    export default App;