import React, { useEffect, useRef, useState } from 'react';
import '../styles/Expertise.css';

const skillClouds = [
  {
    name: "Blockchain Brilliance",
    size: 5,
    description: "Turning trust into a program since 2017",
    skills: [
      { name: "Smart Contracts", level: 90 },
      { name: "DApps", level: 85 },
      { name: "Consensus Algorithms", level: 80 },
      { name: "Tokenomics", level: 75 }
    ]
  },
  {
    name: "IoT Wizardry",
    size: 4,
    description: "Devices talking more to each other than a gossip club",
    skills: [
      { name: "Sensor Networks", level: 88 },
      { name: "Edge Computing", level: 82 },
      { name: "MQTT", level: 79 },
      { name: "LoRaWAN", level: 76 }
    ]
  },
  {
    name: "V2X Communication",
    size: 3,
    description: "Cars chatting about the weather, traffic, or the latest roadblock",
    skills: [
      { name: "DSRC", level: 85 },
      { name: "C-V2X", level: 80 },
      { name: "Platooning", level: 78 },
      { name: "Traffic Optimization", level: 83 }
    ]
  },
  {
    name: "Smart City Solutions",
    size: 4,
    description: "Making cities so smart, they might just start doing your taxes",
    skills: [
      { name: "Urban Planning", level: 87 },
      { name: "Energy Management", level: 84 },
      { name: "Waste Management", level: 81 },
      { name: "Smart Grids", level: 86 }
    ]
  }
];

const Expertise = () => {
  const skillCloudRefs = useRef([]);
  const [activeSkills, setActiveSkills] = useState([]);
  const [visionText, setVisionText] = useState('');

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-skill-cloud');
        } else {
          entry.target.classList.remove('animate-skill-cloud');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    skillCloudRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      skillCloudRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  useEffect(() => {
    const vision = "Innovating for a connected future";
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < vision.length) {
        setVisionText((prev) => prev + vision[index]);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const handleSkillClick = (skill) => {
    setActiveSkills(prev => 
      prev.includes(skill) ? prev.filter(s => s !== skill) : [...prev, skill]
    );
  };

  return (
    <section className="expertise-section" id="expertise">
      <h2>Mastering the Digital Ecosystem</h2>
      <div className="vision-statement">
        <h3>
          {visionText.split('').map((char, index) => (
            <span 
              key={index} 
              style={{
                animation: `fadeIn 0.5s ${index * 0.1}s forwards`,
                opacity: 0,
                display: 'inline-block'
              }}
            >
              {char}
            </span>
          ))}
        </h3>
      </div>
      <div className="skill-clouds">
        {skillClouds.map((cloud, index) => (
          <div 
            key={index} 
            className={`skill-cloud size-${cloud.size}`}
            ref={el => skillCloudRefs.current[index] = el}
          >
            <h3>{cloud.name}</h3>
            <p>{cloud.description}</p>
            <ul>
              {cloud.skills.map((skill, skillIndex) => (
                <li 
                  key={skillIndex}
                  onClick={() => handleSkillClick(skill.name)}
                  className={activeSkills.includes(skill.name) ? 'active' : ''}
                >
                  <span>{skill.name}</span>
                  <div className="skill-bar-container">
                    <div 
                      className="skill-bar" 
                      style={{width: `${skill.level}%`}}
                    ></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expertise;
