// NavbarBottom.js
import React, { useState, useEffect, useCallback } from 'react';
import '../styles/NavbarBottom.css';

const NavbarBottom = () => {
  const [activeSection, setActiveSection] = useState('');

  const navItems = [
    { label: 'WORK', id: 'work' },
    { label: 'EXPERTISE', id: 'expertise' },
    { label: 'VISION', id: 'vision' },
  ];

  const handleScroll = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -60; // Adjust this value based on your navbar height
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-20% 0px -80% 0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <nav className="custom-navbar-background" aria-label="Bottom navigation">
      <ul className="nav-list">
        {navItems.map(({ id, label }) => (
          <li className="nav-item" key={id}>
            <button
              className={`nav-link ${activeSection === id ? 'active' : ''}`}
              onClick={() => handleScroll(id)}
              aria-current={activeSection === id ? 'page' : undefined}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default React.memo(NavbarBottom);
