import React, { useCallback, useMemo } from 'react';
import '../styles/Headerinfo.css';
import profileImage from '../assets/IMG_9685.jpg';

const HeaderInfo = () => {
  const disableContextMenu = useCallback((e) => e.preventDefault(), []);

  const profileImageStyle = useMemo(() => ({
    backgroundImage: `url(${profileImage})`,
  }), []);

  return (
    <div className="header-info">
      <div
        className="profile-image"
        style={profileImageStyle}
        onContextMenu={disableContextMenu}
      >
        <div className="image-overlay" />
      </div>
      <div className="name-title">
        <h1 className="name">Steven Marquez Jr.</h1>
        <h2 className="title">Blockchain Developer</h2>
      </div>
    </div>
  );
};

export default React.memo(HeaderInfo);