import React, { useState } from 'react';
import '../styles/Vision.css';
import { motion } from 'framer-motion';

const Vision = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5 } }
  };

  return (
    <section className="vision-section" id="vision">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="vision-content"
      >
        <motion.h2 variants={itemVariants}>Envisioning a Smarter Tomorrow</motion.h2>
        <motion.div
          variants={itemVariants}
          className="vision-row"
        >
          {['narrative', 'why-it-matters', 'call-to-imagination'].map((section) => (
            <div
              key={section}
              className={`vision-item ${expandedSection === section ? 'expanded' : ''}`}
            >
              <h3 onClick={() => toggleSection(section)}>
                {section === 'narrative' && 'The Future We\'re Building'}
                {section === 'why-it-matters' && 'Why It Matters'}
                {section === 'call-to-imagination' && 'Join the Journey'}
              </h3>
              <p className={section}>
                {section === 'narrative' && 'Imagine a world where your car books your parking spot before you\'ve even left home, thanks to blockchain. That\'s the future I\'m coding towards.'}
                {section === 'why-it-matters' && 'Because efficiency isn\'t just about saving time; it\'s about saving energy, reducing carbon footprints, and making tech that talks, not just computes.'}
                {section === 'call-to-imagination' && 'Join me in creating a world where technology doesn\'t just work for us; it works with us.'}
                {expandedSection === section && (
                  <motion.span
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="expanded-content"
                  >
                    {section === 'narrative' && 'This isn\'t just about convenience; it\'s about creating a seamless, interconnected ecosystem where technology anticipates and fulfills our needs.'}
                    {section === 'why-it-matters' && 'By optimizing our daily interactions with technology, we\'re paving the way for a more sustainable and harmonious coexistence with our digital tools.'}
                    {section === 'call-to-imagination' && 'Together, we can shape a future where innovation serves humanity, enhancing our lives while preserving our planet.'}
                  </motion.span>
                )}
              </p>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Vision;
