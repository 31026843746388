import React from 'react';
import '../styles/HireMeButton.css';

const HireMeButton = () => {
  const handleClick = () => {
    window.open('https://app.usebraintrust.com/auth/login/?next=%2F', '_blank', 'noopener,noreferrer');
  };

  return (
    <button
      onClick={handleClick}
      className="hire-me-button"
      aria-label="Hire Me"
    >
      <span className="availability-dot" />
      Hire Me
    </button>
  );
};

export default HireMeButton;