import React from 'react';
import '../styles/Footer.css';
import { FaGithub, FaLinkedin, FaXTwitter } from 'react-icons/fa6';

const socialLinks = [
  { href: 'https://github.com/stevenmarquez-git', label: 'GitHub', Icon: FaGithub },
  { href: 'https://x.com/stevenmarquez_x', label: 'X', Icon: FaXTwitter },
  { href: 'https://linkedin.com/in/steven-marquez-jr-b2155a238', label: 'LinkedIn', Icon: FaLinkedin }
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <p className="footer-message">&copy; {currentYear} Steven Marquez Jr.</p>
        <p className="footer-submessage">Crafted with House mixes on repeat.</p>
        <div className="social-icons">
          {socialLinks.map(({ href, label, Icon }) => (
            <a
              key={label}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
            >
              <Icon />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;