import React, { useState, useEffect } from 'react';
import '../styles/CallToAction.css';
import '../context/ThemeMode.css';
import { FaHandshake, FaLightbulb, FaCogs, FaRocket, FaChevronDown } from 'react-icons/fa';
import { FaXTwitter, FaLinkedin } from 'react-icons/fa6';

const CTAButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="cta-button"
    aria-label="Call to action"
  >
    {children}
  </button>
);

const CTAText = ({ text }) => (
  <p className="cta-text">{text}</p>
);

const CTAForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <form onSubmit={handleSubmit} className="cta-form">
      <label htmlFor="email" className="visually-hidden">Email</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        required
        aria-required="true"
      />
      <button type="submit" aria-label="Subscribe">Send</button>
    </form>
  );
};

const SocialLinks = () => (
  <div className="social-links">
    <a href="https://x.com/stevenmarquez_x" target="_blank" rel="noopener noreferrer" aria-label="Message me on X" className="social-button">
      <FaXTwitter /> Reach out on X
    </a>
    <a href="https://linkedin.com/in/steven-marquez-jr-b2155a238" target="_blank" rel="noopener noreferrer" aria-label="Message me on LinkedIn" className="social-button">
      <FaLinkedin /> Reaach out on LinkedIn
    </a>
  </div>
);

const FreelanceProcess = () => (
  <div className="freelance-process">
    <CTAText text="From concept to code: Crafting your ideal smart contract!" />
    <div className="process-steps">
      <div className="process-step">
        <FaHandshake className="process-icon" />
        <h4>1. Consultation</h4>
        <p>We discuss your needs and goals to ensure we're on the same page.</p>
      </div>
      <div className="process-step">
        <FaLightbulb className="process-icon" />
        <h4>2. Ideation</h4>
        <p>I brainstorm and propose innovative solutions tailored to your project.</p>
      </div>
      <div className="process-step">
        <FaCogs className="process-icon" />
        <h4>3. Development</h4>
        <p>I bring the ideas to life with cutting-edge development practices.</p>
      </div>
      <div className="process-step">
        <FaRocket className="process-icon" />
        <h4>4. Launch</h4>
        <p>We review, refine, and launch your project for maximum impact.</p>
      </div>
    </div>
    <CTAButton
      onClick={() => window.open('https://app.usebraintrust.com/auth/login/?next=%2F', '_blank', 'noopener,noreferrer')}
    >
      Schedule a Consultation
    </CTAButton>
  </div>
);

const CallToAction = () => {
  const [greeting, setGreeting] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) setGreeting('Good morning');
    else if (hour < 18) setGreeting('Good afternoon');
    else setGreeting('Good evening');
  }, []);

  const handleCTAClick = () => {
    setShowMore(!showMore);
    setUserInteracted(true);
  };

  const handleSubmit = (email) => {
    console.log(`Subscribed with email: ${email}`);
    // Here you would typically send this to your backend
  };

  const handleArrowClick = () => {
    const workSection = document.getElementById('work');
    if (workSection) {
      workSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="call-to-action" role="region" aria-label="Call to action section">
      <CTAText text={`${greeting}! Ready to revolutionize your approach?`} />
      <CTAButton onClick={handleCTAClick}>
        {showMore ? 'Show Less' : 'Click Here'}
      </CTAButton>
      {showMore && (
        <div className="more-info" aria-live="polite">
          <FreelanceProcess />
          <CTAText text="Let's connect and Make Something Remarkable—hit me up!"/>
          <div className="contact-options">
            <CTAForm onSubmit={handleSubmit} />
            <SocialLinks />
          </div>
        </div>
      )}
      {userInteracted && !showMore && (
        <CTAText text="Thanks for your interest!" />
      )}
      <div className={`animated-arrow ${showMore ? 'hidden' : ''}`} onClick={handleArrowClick}>
        <FaChevronDown className="bounce" />
      </div>
    </div>
  );
};

export default CallToAction;
