import React, { useState, useEffect, useCallback } from 'react';
import '../context/ThemeMode.css';

const ThemeMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check for user preference in localStorage
    const storedPreference = localStorage.getItem('isDarkMode');
    if (storedPreference !== null) {
      return JSON.parse(storedPreference);
    } else {
      // Fallback to system preference
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  });

  // Apply the theme to the document root
  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Listen to system preference changes if no user preference is set
  useEffect(() => {
    const storedPreference = localStorage.getItem('isDarkMode');
    if (storedPreference === null) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (e) => setIsDarkMode(e.matches);

      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);

  // Detect device light or dark mode
  useEffect(() => {
    const detectDeviceTheme = () => {
      const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(isDarkTheme);
    };

    // Initial detection
    detectDeviceTheme();

    // Listen for changes in device theme
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addListener(detectDeviceTheme);

    // Cleanup listener
    return () => mediaQuery.removeListener(detectDeviceTheme);
  }, []);

  // Toggle theme and persist user preference
  const toggleTheme = useCallback(() => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  }, []);

  return (
    <button
      className="theme-toggle-button"
      type="button"
      onClick={toggleTheme}
      aria-label={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
    >
      <i className={`bi bi-${isDarkMode ? 'sun' : 'moon'}`}></i>
    </button>
  );
};

export default ThemeMode;
